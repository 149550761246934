/* AboutMe.css */
.about-me-container {
    background-image: url('https://res.cloudinary.com/dmfvjevbr/image/upload/v1697660121/image1_kpbscr.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh; /* Set the height as needed */
    animation: changeBackground 100s infinite steps(1);
}

.about-me-content {
    position: relative;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px; /* Reduced padding here */
    text-align: left;
    z-index: 1;
    max-width: 1000vh;
    max-height: 70vh;
    overflow-y: auto;
}

@keyframes changeBackground {
    0% {
        background-image: url('https://res.cloudinary.com/dmfvjevbr/image/upload/v1697660177/image13_qgogur.jpg');
    }
    20% {
        background-image: url('https://res.cloudinary.com/dmfvjevbr/image/upload/v1697661791/IMG_4069_byp4nv.jpg');
    }
    40% {
        background-image: url('https://res.cloudinary.com/dmfvjevbr/image/upload/v1697660121/image1_kpbscr.jpg');
    }
    /* Add more keyframes for additional images */
    100% {
        background-image: url('https://res.cloudinary.com/dmfvjevbr/image/upload/v1697660121/image1_kpbscr.jpg');
    }
}




