/* Footer.css */

/* Style for the footer */
.footer {
    position: fixed;
    bottom: 0; /* Position the footer at the bottom */
    width: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.2); /* Add opacity with RGBA (A is for alpha) */
    backdrop-filter: blur(5px); /* Optional: Apply a blur effect to the background */
  }
  
  /* Remove focus outline on the footer */
  .footer:focus {
    outline: none;
  }
  
  /* Style for the links in the footer */
  .footer-link {
    color: white; /* Set the text color to white */
  }
  
  /* Change link color on hover with smooth transition */
  .footer-link:hover {
    color: grey; /* Set the text color to grey when hovered */
  }
  
  /* Style for the social media icons */
  .social-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: white; /* Set the text color to white */
    font-size: 24px; /* Adjust the font size as needed */
    margin: 0 10px; /* Add some spacing between the icons */
    transition: color 0.3s; /* Add a smooth transition for color change*/
  }
  
  /* Style for the social media icon links */
  .social-icon {
    color: white; /* Set the text color to white */
    font-size: 24px; /* Adjust the font size as needed */
    margin: 0 10px; /* Add some spacing between the icons */
    transition: color 0.3s; /* Add a smooth transition for color changes */
  }
  
  /* Change social media icon color on hover */
  .social-icon:hover {
    color: grey; /* Set the icon color to grey when hovered */
  }
  