/* Header.css */
.navbar {
    position: absolute;
    width: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
  }

  .navbar-toggler:focus {
    outline: none;
  }

  .navbar-brand {
    color: white;
    font-family: Arial, sans-serif;
    font-size: 20px;
  }

  .navbar-brand:hover {
    color: grey;
  }

  .navbar-toggler-icon {
    background-color: white;
  }

  .navbar-toggler-icon.open {
    background-color: white;
  }

  .white-link {
    color: white;
    transition: color 0.3s;
  }

  .white-link:hover {
    color: grey;
  }

  /* Styling for the dropdown menu */
  .dropdown-menu {
    background: rgba(0, 0, 0, 0.0);
    border: none; /* Remove borders */
  }

  .dropdown-item {
    color: white;
  }

  .dropdown-item:hover {
    background-color: grey;
  }
